import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './assets/css/vendor/bootstrap-float-label.min.css';
import './assets/css/vendor/bootstrap.css';
import './assets/font/iconsmind-s/css/iconsminds.css';
import './assets/font/simple-line-icons/css/simple-line-icons.css';

import 'rsuite/dist/rsuite.min.css';

import './index.css';
import './assets/styles/admin.css';
import './assets/css/portalplus.css';

import { check_login_bool } from "./ressources/functions/Login";

import { checkAcl } from "./adminressources/ACLHelper";
import { loadConfig, config as getConfig } from "./ressources/functions/Config";
import { WebSocketSetup } from "./views/socket";
import { getPillar } from "./views/administration/Pillar";
import { decrypt_storage_values } from './ressources/functions/helper';
import { getFacets } from './ressources/functions/Query';
import { loadTrashcan } from './ressources/functions/Trashcan';

loadConfig();

const App = lazy(() => import('./App'));
const Albums = lazy(() => import('./views/albums/Albums'));
const Albumpreview = lazy(() => import('./views/albums/pages/Albumpreview'));
const Events = lazy(() => import('./views/events/Eventslist'));
const Event = lazy(() => import('./views/events/pages/event'));
const Gallery = lazy(() => import('./views/Gallery'));
const Studentslist = lazy(() => import('./views/Studentslist'));
const Profile = lazy(() => import('./views/people/profile'));
const Bin = lazy(() => import('./views/trashcan/Bin'));
const Binempty = lazy(() => import('./views/trashcan/Binempty'));
const Upload = lazy(() => import('./views/Upload'));
const Welcome = lazy(() => import('./views/Welcome'));

// ADMINISTRATION SECTION
const Administration = lazy(() => import('./views/administration/Admin'));
const About = lazy(() => import('./views/administration/pages/About'));
const Logs = lazy(() => import('./views/administration/pages/about/Logs'));
const OnlineUsers = lazy(() => import('./views/administration/pages/about/OnlineUsers'));
const Reindex = lazy(() => import('./views/administration/pages/about/Reindex'));
const SystemRess = lazy(() => import('./views/administration/pages/about/SystemRessources'));
const VersionInfo = lazy(() => import('./views/administration/pages/about/VersionInformation'));
const Consent = lazy(() => import('./views/administration/pages/Consent'));
const MediaConsent = lazy(() => import('./views/administration/pages/consent/MediaConsent'));
const Recognition = lazy(() => import('./views/administration/pages/consent/Recognition'));
const Renditions = lazy(() => import('./views/administration/pages/consent/Renditions'));
const SocialMedia = lazy(() => import('./views/administration/pages/consent/SocialMedia'));
const Watermark = lazy(() => import('./views/administration/pages/consent/Watermark'));
const Integration = lazy(() => import('./views/administration/pages/Integration'));
const Calendar = lazy(() => import('./views/administration/pages/integration/Calendar'));
const Ldap = lazy(() => import('./views/administration/pages/security/Ldap'));
const Sis = lazy(() => import('./views/administration/pages/integration/Sis'));
const Webdav = lazy(() => import('./views/administration/pages/integration/Webdav'));
const MobileApp = lazy(() => import('./views/administration/pages/Mobile'));
const Mobilesettings = lazy(() => import('./views/administration/pages/mobile/Mobilesettings'));
const Portal = lazy(() => import('./views/administration/pages/Portal'));
const PortalLogo = lazy(() => import('./views/administration/pages/portal/PortalLogo'));
const PortalOrders = lazy(() => import('./views/administration/pages/portal/PortalOrders'));
const PortalPublish = lazy(() => import('./views/administration/pages/portal/PortalPublish'));
const PortalQuickPublish = lazy(() => import('./views/administration/pages/portal/PortalQuickPublish'));
const PortalSSL = lazy(() => import('./views/administration/pages/portal/PortalSSL'));
const PortalSSO = lazy(() => import('./views/administration/pages/portal/PortalSSO'));
const PortalTerms = lazy(() => import('./views/administration/pages/portal/PortalTerms'));
const PortalWebshop = lazy(() => import('./views/administration/pages/portal/PortalWebshop'));
const AccessSecurity = lazy(() => import('./views/administration/pages/Security'));
const AccessLists = lazy(() => import('./views/administration/pages/security/AccessLists'));
const MultiCampus = lazy(() => import('./views/administration/pages/security/MultiCampus'));
const ImpersonateUser = lazy(() => import('./views/administration/pages/security/ImpersonateUser'));
const RemoteAccess = lazy(() => import('./views/administration/pages/security/RemoteAccess'));
const SSL = lazy(() => import('./views/administration/pages/security/Ssl'));
const SSO = lazy(() => import('./views/administration/pages/security/Sso'));
const Settings = lazy(() => import('./views/administration/pages/Settings'));
const Finishinstall = lazy(() => import('./views/administration/pages/settings/Finishinstall'));
const Logo = lazy(() => import('./views/administration/pages/settings/Logo'));
const Systemregistration = lazy(() => import('./views/administration/pages/settings/Systemregistration'));

const Socket = lazy(() => import('./views/socket'));

const PortalPlus = lazy(() => import('./views/portalplus/PortalPlus'));
const PortalPlusInfo = lazy(() => import('./views/portalplus/PortalPlusInfo'));
const Collections = lazy(() => import('./views/portalplus/pages/Collections'));
const Collection = lazy(() => import('./views/portalplus/pages/ViewCollection'));
const CreateCollection = lazy(() => import('./views/portalplus/pages/CreateCollection'));
const IDCheckPending = lazy(() => import('./views/portalplus/pages/IDCheck/Pending'));
const IDCheckApproved = lazy(() => import('./views/portalplus/pages/IDCheck/Approved'));
const IDCheckRejected = lazy(() => import('./views/portalplus/pages/IDCheck/Rejected'));
const Customise = lazy(() => import('./views/portalplus/pages/Customise'));
const StatsPortal = lazy(() => import('./views/portalplus/pages/Stats'));

const Webshop = lazy(() => import('./views/portalplus/pages/Webshop'));

const PortalPlusPublish = lazy(() => import('./views/administration/pages/portalplus/PortalPlusPublish'));
const PortalPlusRegistration = lazy(() => import('./views/administration/pages/portalplus/PortalPlusRegistration'));
const PortalPlusSSL = lazy(() => import('./views/administration/pages/portalplus/PortalPlusSSL'));
const PortalPlusSSO = lazy(() => import('./views/administration/pages/portalplus/PortalPlusSSO'));
const PortalPlusTerms = lazy(() => import('./views/administration/pages/portalplus/PortalPlusTerms'));
const PortalPlusWebshop = lazy(() => import('./views/administration/pages/portalplus/PortalPlusWebshop'));
const PortalPlusIcon = lazy(() => import('./views/administration/pages/portalplus/PPIcon'));

// const client = new QueryClient();

if (document.URL.match("/sso?")) {
  fetch(document.URL).then(resp => {
    return resp.json()
  }).then(result => {
    // 
  })
}

if (document.URL.match("/samllogin/")) {
  check_login_bool();
}

if (document.URL.match("/")) {
  check_login_bool();
}

WebSocketSetup();

// add a micro timeout to allow save and api to hit
setTimeout(() => {

  // tries and loads the token and validate from the cache
  let token = localStorage.getItem("T");
  if (token !== undefined && token !== null) {
    token = decrypt_storage_values(token);
  }
  let valid = localStorage.getItem("valid");

  // loads the login and config from the cache
  let login = localStorage.getItem("L");
  if (login !== undefined && login !== null) {
    login = JSON.parse(decrypt_storage_values(login));
  }
  let config = getConfig;
  // Sets all pages that are hidden depending on ACLs to return to welcome page
  let galleryRoute = <Route path="/gallery" element={<Navigate replace to="/welcome" />} />

  let profileRoute = <Route path="/profileslist" element={<Navigate replace to="/welcome" />} />

  let profileIDRoute = <Route path="/profileslist/:id" element={<Navigate replace to="/welcome" />} />

  let upload = <Route path="/upload" element={<Navigate replace to="/welcome" />} />

  let collection = <Route path="/PortalPlus" element={<Navigate replace to="/welcome" />} />

  // If the user has logged in and the config has loaded
  if (login !== undefined && login !== null && config !== undefined && config !== null) {
    getPillar();
    getFacets();
    loadTrashcan();
    // If the login returns roles to calculate ACLs on
    if (login.roles !== undefined && login.roles !== null) {
      // Makes sure there are roles to calculate
      if (login.roles.length !== undefined) {

        // Checks if the user has View Gallery permission or is Admin
        if (checkAcl(login.roles, config.acls, "VIEW_GALLERY") || checkAcl(login.roles, config.acls, "ADMIN")) {
          galleryRoute = <Route path="/gallery" element={<Gallery />} />

          profileRoute = <Route path="/profileslist" element={<Studentslist />} />

          profileIDRoute = <Route path="/profileslist/:id" element={<Profile />} />
        }

        // Changes the route if the user has create or ADmin access
        if ((checkAcl(login.roles, config.acls, "CREATE") || checkAcl(login.roles, config.acls, "ADMIN")) && login.is_active) {

          if (config.expired !== undefined) {
            if (!config.expired) {
              upload = <Route path="/upload" element={<Upload />} />
            }
          } else {
            upload = <Route path="/upload" element={<Upload />} />
          }
        }

        // Change the route if the user has the collection permission
        if (checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")) {
          if (config.portal_valied) {
            collection = <Route path="/portalplus" element={<PortalPlus />} />
          } else if (!config.portal_valied) {
            collection = <Route exact path="/portalplus" element={<Navigate replace to="/yourcommunity" />} />
          }
        } else {
          collection = <Route exact path="/portalplus" element={<Navigate replace to="/yourcommunity" />} />
        }
      }

    }

  }

  // Checks to make sure they have a valid subscription aswell otherwise limits the response
  if (token !== null && token !== undefined && valid !== 'false' && valid !== undefined && valid !== 'undefined' && (login !== null && login !== undefined && login.roles !== undefined) && config !== null && config !== undefined) {
    // If the user is an admin, returns all links plus admin
    if (checkAcl(login.roles, config.acls, "ADMIN")) {

      const root = ReactDOM.createRoot(document.getElementById("root"));
      root.render(
        <Suspense fallback={<span></span>}>
          <BrowserRouter>
              <Routes>
                <Route exact path="/" element={<App />} />
                <Route path="/logout" element={<App />} />
                <Route path="/welcome" element={<Welcome />} />
                {galleryRoute}
                <Route path="/events" element={<Events />} />
                <Route path="/events/:name" element={<Event />} />
                {profileIDRoute}
                {profileRoute}
                <Route path="/albums/favourite" element={<Albumpreview />} />
                <Route path="/albums" element={<Albums />} />
                <Route path="/albums/:id" element={<Albumpreview />} />
                {upload}
                <Route path="/bin" element={<Bin />} />
                <Route path="/binempty" element={<Binempty />} />
                <Route path="/administration" element={<Administration />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/logo" element={<Logo />} />
                <Route path="/systemregistration" element={<Systemregistration />} />
                <Route path="/finishinstall" element={<Finishinstall />} />
                <Route path="/integration" element={<Integration />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/ldap" element={<Ldap />} />
                <Route path="/sis" element={<Sis />} />
                <Route path="/webdav" element={<Webdav />} />
                <Route path="/accesssecurity" element={<AccessSecurity />} />
                <Route path="/permissions" element={<AccessLists />} />
                <Route path="/multicampus" element={<MultiCampus />} />
                <Route path="/impersonateuser" element={<ImpersonateUser />} />
                <Route path="/remoteaccess" element={<RemoteAccess />} />
                <Route path="/ssl" element={<SSL />} />
                <Route path="/asso" element={<SSO />} />
                <Route path="/imageconsent" element={<Consent />} />
                <Route path="/mediaconsent" element={<MediaConsent />} />
                <Route path="/renditions" element={<Renditions />} />
                <Route path="/socialmedia" element={<SocialMedia />} />
                <Route path="/watermark" element={<Watermark />} />
                <Route path="/facialrecognition" element={<Recognition />} />
                <Route path="/parentportal" element={<Portal />} />
                <Route path="/portalssl" element={<PortalSSL />} />
                <Route path="/portalsso" element={<PortalSSO />} />
                <Route path="/portallogo" element={<PortalLogo />} />
                <Route path="/portalterms" element={<PortalTerms />} />
                <Route path="/portalpublishingcriteria" element={<PortalPublish />} />
                <Route path="/portalwebshop" element={<PortalWebshop />} />
                <Route path="/portalorders" element={<PortalOrders />} />
                <Route path="/portalquickpublish" element={<PortalQuickPublish />} />
                <Route path="/about" element={<About />} />
                <Route path="/logs" element={<Logs />} />
                <Route path="/systemressources" element={<SystemRess />} />
                <Route path="/onlineusers" element={<OnlineUsers />} />
                <Route path="/reindex" element={<Reindex />} />
                <Route path="/version" element={<VersionInfo />} />
                <Route path="/mobileapp" element={<MobileApp />} />
                <Route path="/mobilesettings" element={<Mobilesettings />} />
                <Route path="/socket" element={<Socket />} />


              <Route path="/yourcommunity" element={<PortalPlusInfo />} />

              {!config.portal_valied
                ? <Route exact path="/portalplus" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/portalplus" element={<PortalPlus />} />
              }
              {!config.portal_valied
                ? <Route exact path="/collections/all" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/collections/all" element={<Collections />} />
              }

              {!config.portal_valied
                ? <Route exact path="/collections/collection" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/collections/:id" element={<Collection />} />
              }
              {!config.portal_valied
                ? <Route exact path="/create" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/create" element={<CreateCollection />} />
              }
              {!config.portal_valied
                ? <Route exact path="/idcheckpending" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/idcheckpending" element={<IDCheckPending />} />
              }
              {!config.portal_valied
                ? <Route exact path="/idcheckapproved" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/idcheckapproved" element={<IDCheckApproved />} />
              }
              {!config.portal_valied
                ? <Route exact path="/idcheckrejected" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/idcheckrejected" element={<IDCheckRejected />} />
              }
              {!config.portal_valied
                ? <Route exact path="/customise" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/customise" element={<Customise />} />
              }
              {!config.portal_valied
                ? <Route exact path="/statistics" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/statistics" element={<StatsPortal />} />
              }
              {!config.portal_valied
                ? <Route exact path="/webshop" element={<Navigate replace to="/yourcommunity" />} />
                : <Route path="/webshop" element={<Webshop />} />
              }

              <Route path="/portalplusregistration" element={<PortalPlusRegistration />} />
              <Route path="/portalplusssl" element={<PortalPlusSSL />} />
              <Route path="/portalplussso" element={<PortalPlusSSO />} />
              <Route path="/portalplusterms" element={<PortalPlusTerms />} />
              <Route path="/portalpluspublishingcriteria" element={<PortalPlusPublish />} />
              <Route path="/portalpluswebshop" element={<PortalPlusWebshop />} />
              <Route path="/portalplusicon" element={<PortalPlusIcon />} />
              <Route path="*" element={<Navigate to="/welcome" replace />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      );
    } else {


      // Returns access to pages only accessable based on ACLs
      const root = ReactDOM.createRoot(document.getElementById("root"));
      root.render(
        <Suspense fallback={<span></span>}>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<App />} />
              <Route path="/logout" element={<App />} />
              <Route path="/welcome" element={<Welcome />} />
              {galleryRoute}
              <Route path="/events/:name" element={<Event />} />
              <Route path="/events" element={<Events />} />
              {profileIDRoute}
              {profileRoute}
              <Route path="/albums/favourite" element={<Albumpreview />} />
              <Route path="/albums/:id" element={<Albumpreview />} />
              <Route path="/albums" element={<Albums />} />
              {upload}
              <Route path="/bin" element={<Bin />} />
              <Route path="/binempty" element={<Binempty />} />
              <Route path="/yourcommunity" element={<PortalPlusInfo />} />

              {collection}

              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/portalplus" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/portalplus" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/portalplus" element={<PortalPlus />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/collections/all" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/collections/all" element={<Navigate to="/yourcommunity" />} /> : <Route path="/collections/all" element={<Collections />} />)
              }

              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/collections/:id" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/collections/:id" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/collections/:id" element={<Collection />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/create" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/create" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/create" element={<CreateCollection />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/idcheckpending" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/idcheckpending" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/idcheckpending" element={<IDCheckPending />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/idcheckapproved" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/idcheckapproved" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/idcheckapproved" element={<IDCheckApproved />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/idcheckrejected" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/idcheckrejected" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/idcheckrejected" element={<IDCheckRejected />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/customise" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/customise" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/customise" element={<Customise />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/statistics" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/statistics" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/statistics" element={<StatsPortal />} />)
              }
              {login.roles !== undefined && !checkAcl(login.roles, config.acls, "EVENT_MANAGEMENT")
                ? <Route exact path="/webshop" element={<Navigate replace to="/yourcommunity" />} />
                : (!config.portal_valied ? <Route exact path="/webshop" element={<Navigate replace to="/yourcommunity" />} /> : <Route path="/webshop" element={<Webshop />} />)
              }

              <Route path="*" element={<Navigate to="/welcome" />} />
            </Routes>
          </BrowserRouter>
        </Suspense >
      );
    }
  } else {
    // first time hitting, returns then to the login page
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <Suspense fallback={<span></span>}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<App />} />
            <Route path="/logout" element={<App />} />
            <Route path="/samllogin" element={<App />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  }

}, 500)
