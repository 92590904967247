import { getLogin } from "../ressources/functions/helper";
import { config as getConfig } from "../ressources/functions/Config";
import { multicampus_checker, MultiCampus } from "../ressources/functions/Login";

/**
 * This Class is to calculate the logged in users ACL permissions. Based on ACL permissions will allow users todo selected functions
 */

export const aclView = "VIEW_PROPERTIES"
export const aclAdmin = "ADMIN";
export const aclAlbumShare = "ALBUM_SHARE";
export const aclAlbumCreate = "ALBUM_CREATE";
export const aclAlbumDelete = "ALBUM_DELETE";
export const aclAlbumEdit = "ALBUM_EDIT";
export const aclAlbumUpload = "ABLUM_UPLOAD";
export const aclAlbumUploadShare = "ALBUM_UPLOAD_SHARE";
export const aclAlbumDownload = "ALBUM_DOWNLOAD";
export const aclExternalAdd = "ALBUM_EXTERNAL_ADD";
export const aclExternalUpload = "ALBUM_EXTERNAL_UPLOAD";
export const aclExternalDelete = " ALBUM_EXTERNAL_DELETE";
export const aclExternalView = "ALBUM_EXTERNAL_VIEW";
export const aclExternalEdit = "ALBUM_EXTERNAL_EDIT";
export const aclCurationDelete = "ALBUM_CURATION_DELETE";
export const aclCurationEdit = "ALBUM_CURATION_EDIT";
export const aclCurationDownload = "ALBUM_CURATION_DOWNLOAD";
export const aclUploadGallery = "ALBUM_TO_GALLERY";
export const aclViewCuration = "VIEW_CURATION";

// Params: 
// roles: the user role Pillar.acls
// acls: Comes from the Pillar
// action: The action to check with, one of albumTypes in Pillar
// params: No used for now
//

export const checkAcl = (roles, acls, action, params) => {
    if(acls !== undefined && acls !== null){

        let acl = acls.filter(acl => acl.actions.some(acl_action => acl_action === action)).find(acl => { // Filters and gets a list of ACLS that have that action

            // Maps and finds ACL that contains the correct role that search for.
            return acl.roles.map(role => {
                if (typeof role === 'string') {
                    return role;
                } else {
                    return role(params);
                }
    
            }).some(role => roles.indexOf(role) !== -1);
    
        });
        // Checks to see if ACL has outcome allowed
        if (acl !== undefined && acl !== null) {

            return acl.outcome === "ALLOW";
        }
    }
    
    return false;
}

// Method for returning the highest permission level, User friendly name.. e.g "ADMIN" or "Marketing"
// This allows Marketing access to selected methods without generating a new acls 
export const getRole = (roles, acls) => {
    let names = [];
    let permission = "";

    // Loops through each role the logged in user is part of
    roles.forEach(role => {
        acls.forEach(acl => {
            if(acl.roles.includes(role)){
                // If its not already added and the acl name is not undefined will add it to the list
                if(!names.includes(acl.acl_name) && acl.acl_name !== undefined && acl.acl_name !== null){
                    names.push(acl.acl_name);
                }
            }
        });
    })

    // If statements that go down the order from highest to lowest permission and returning the Name of your current permission
    if(names.includes("Admin")){
        permission = "Admin";
    }else if (names.includes("SchoolBench Admin")){
        permission = "SchoolBench Admin"
    } else if (names.includes("Marketing")){
        permission = "Marketing";
    } else if (names.includes("Collaborators")){
        permission = "Collaborator"
    } else if (names.includes("Contributors")){
        permission = "Contributor"
    } else if (names.includes("Viewer")){
        permission = "Viewer"
    } else if (names.includes("View")){
        permission = "Viewer"
    }
    
    return permission;
}

// Calculates if the fields match using regex expression
const matches = (value_match, input) => {

    let value = input.field !== undefined && input.field !== null ? input.field.value : input;

    if (value) {
        return value.some(val => matches(value_match, val));
    } else {

        let val = value + "";

        if (value_match.value !== undefined && value_match.value !== null) {
            return val === value_match.value;
        }

        if (value_match.regex !== undefined && value_match.regex !== null) {

            let regex = new RegExp(value_match.regex.expression);

            return regex.test(val) === value_match.regex.requires_match;
        }

        return true;

    }


}

// Checks if the conditions match the field requirements, used for updating fields
export const conditionMatches = (create_mode, conditions, fields) => {

    if (conditions.create_mode !== undefined && conditions.create_mode !== null) {
        if (create_mode !== conditions.create_mode) {
            return false;
        }
    }

    if (conditions.edit_mode !== undefined && conditions.edit_mode !== null) {
        if (create_mode === conditions.edit_mode) {
            return false;
        }
    }

    let change_fields = Object.keys(fields).filter(field => {

        let val = fields[field];

        if (val !== undefined && val !== null) {
            if (val.field !== undefined && val.field !== null) {
                val = val.field.value;
            }
            if (val) {
                return val.some(val => (val + "").length > 0)
            } else {
                return (val + "").length > 0
            }
        }

        return false;
    });

    if (conditions.required_fields !== undefined && conditions.required_fields !== null) {
        let has_missing_fields = conditions.required_fields.some(field => change_fields.indexOf(field) === -1);

        if (has_missing_fields) {
            return false;
        }
    }

    if (conditions.absent_fields !== undefined && conditions.absent_fields !== null) {

        let has_fields = conditions.absent_fields.some(field => change_fields.indexOf(field) !== -1);

        if (has_fields) {
            return false;
        }
    }

    if (conditions.matched_values !== undefined && conditions.matched_values !== null) {
        let matched_values = conditions.matched_values;

        let has_different_value = Object.keys(matched_values).some(field => {

            if (fields[field] === undefined) {
                return true;
            }

            let val = fields[field];

            return !matches(matched_values[field], val);

        });

        if (has_different_value) {
            return false;
        }
    }

    if (conditions.unmatched_values !== undefined && conditions.unmatched_values !== null) {
        let unmatched_values = conditions.unmatched_values;
        let has_same_value = Object.keys(unmatched_values).some(field => {
            if (fields[field] !== undefined && fields[field] !== null) {
                let val = fields[field];

                return matches(unmatched_values[field], val);
            }

            return false;
        })

        if (has_same_value) {
            return false;
        }
    }


    return true;
}

// checking multi campus is on or not
export const checkMultiCampus = () => {
    let config = getConfig
    let login = getLogin()

    let isMultiCampus = false
    if (config != null) {
        if (config.multicampus != null) {
            if (config.multicampus.length > 0) {
                isMultiCampus = true;
            }
        }
    }

    if (isMultiCampus && MultiCampus == null) {
        multicampus_checker(login);
    }
    
    return isMultiCampus
}

// multi campus admin checker
export const AdminChecker = () => {
    let config = getConfig
    let login = getLogin()

    let isAdmin = checkAcl(login.roles, config.acls, "ADMIN");

    return isAdmin
}

// Get the list of campuses allocated to the user
export const getMultiCampuslist = () => {
    let config = getConfig
    let login = getLogin()

    let isAdmin = checkAcl(login.roles, config.acls, "ADMIN");

    let campus = []
    if (config != null) {
        if (config.multicampus != null) {
            if (config.multicampus.length > 0) {
                if (isAdmin){
                    config.multicampus.map((items) => { return campus = [...campus, ...items.campus] })
                    campus = [...new Set(campus)];
                } else {
                    campus = MultiCampus;
                    if (campus == null) {
                        campus = [];
                    }
                }
            }
        }
    }
    
    return campus
}
